<!-- 缩略图列表，展示为一排缩略图，一般用于上传图片或显示一组图片时 -->

<template>
    <ul class="thumbnail-list" v-if="listAlready"
        :class="{block: showLine}">

        <!-- <template v-for="(image, index) in images"> -->
            <!-- 如果数据的 percentage 属性为小于100的数字，则显示一个进度动画 -->
            <!-- <li v-if="image.percentage < 100" :style="sizeStyle"
                :key="image.name || image.path || image">

                <el-progress :percentage="parseInt(image.percentage) || 0" type="dashboard"></el-progress>
            </li>

            <li v-else-if="type==='text'" class="text"
                :key="'t'+(image.path || image.name || image.url || image)"
                @click.stop="handleThumbClick(index)">

                <div class="name-tag" v-if="textType=='index'">附件{{ index+1 }}</div>
                <div class="name-tag" v-else>{{ image.name || image.cname || image }}</div>
            </li> -->

            <!-- 否则，即显示图片 -->
            <li v-for="(image, index) in images"
                :key="'i'+(image.path || image.name || image.url || image)"
                @click.stop="handleThumbClick(index)"
                :class="'cols'+cols"
            >

                <div v-if="image.isImage" :style="sizeStyle + 'background-image: url(' + encodeURI(image.url || image.path || image) + ')'"
                    class="img-place"
                ></div>
                <div v-else class="img-place"></div>

                <div class="file-profile">
                    <span>{{ image.cname || image.name }}</span>
    
                </div>

                <div class="file-notes">
                    <span v-if="image.percentage < 100">正在上传({{Math.floor(image.percentage)}}%)</span>
                    <i class="el-icon-delete" v-if="showDelete"
                        @click.stop="handleDeleteClick(index)"></i>
                </div>
            </li>
        <!-- </template> -->
    </ul>
</template>

<script>

export default {
    name: 'ThumbnailList1',
    props: {
        images: {
            type: Array,
            require: true,
            default: function () {
                return []
            }
        },
        showDelete: {
            type: Boolean,
            default: false
        },
        read: { // 可查看大图
            type: Boolean,
            default: true
        },
        size: Number,
        type: {
            type: String,
            default: "image"
        },
        textType: {
            type: String,
            default: ""
        },
        showLine: {
            type: Boolean,
            default: false
        },
        cols: {
            type: Number,
            default: 2
        }
    },

    computed: {
        listAlready () {
            return (this.images instanceof Array) && this.images.length
        },

        sizeStyle () {
            // const size = this.size || (this.showDelete ? 120 : 46);
            // return `width: ${size}px; height: ${size}px;`;
            return "";
        }
    },

    watch: {
        images: {
            immediate: true,
            deep: true,
            handler (list) {
                list.forEach(it => {
                    it.isImage = it.isImage || it.path?.endsWith(".png") ||
                        it.path?.endsWith(".jpg") ||
                        it.path?.endsWith(".jpeg") ||
                        it.path?.endsWith(".gif");
                    it.cname = it.name || it.path.slice(it.path.lastIndexOf(".")+1)+"文件"
                });
            }
        }
    },

    methods: {

        handleThumbClick(index) {
            if (!this.read) return this.$message({
                message: "您没有查看权限",
                type: "warning"
            });
            // console.log(image);
            // this.$hevueImgPreview(image.url || image.path || image)
            const item = this.images[index];
            if (item.isImage) {
                const list = this.images.filter(it =>it.isImage);
                const index = list.indexOf(item);
                this.$viewerApi({
                    images: list.map(item => encodeURI(item.url || item.path || item)),
                    options: {
                        initialViewIndex: index
                    }
                })
            }
            else window.open(encodeURI(item.url || item.path || item))
        },

        handleDeleteClick(index) {
            const item = this.images[index]
            this.$confirm(`您确定要删除这${item.isImage?"张图片":"个文件"}吗？`, "提示")

            .then(() => {
                this.$emit("delete", index)
            })

            .catch(reason => {
                console.log("取消删除", reason)
            })
        },

        // showFileName (file) {
        //     const isImage = file.path.endsWith(".png") ||
        //         file.path.endsWith(".jpg") ||
        //         file.path.endsWith(".jpeg") ||
        //         file.path.endsWith(".gif");
            
        //     if (isImage) return "";
        //     else return file.name;
        // }
    },
}
</script>

<style lang='scss' scoped>
    ul {
        &:not(.block) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            min-width: 100%;

        }
        // margin-bottom: -10px;

        // &:nth-child(n+2) {
        //     margin-top: 10px;
        // }
    }
    
    li {
        display: flex;
        align-items: center;
        // justify-content: center;
        // height: 56px;
        padding: 12px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #E0DEDC;
        position: relative;
        margin-bottom: 10px;

        // &:not(.text) {
        //     border-radius: 2px;
        //     background-color: whitesmoke;
        //     background-size: cover;
        //     background-position: center;
        // }
        &.cols1 {
            flex-basis: 100%;
        }
        &.cols2 {
            flex-basis: 48%;
            // margin-right: 10px;
        }

        &:not(.unclickable) {
            cursor: pointer;
        }

        .img-place {
            width: 32px;
            height: 32px;
            border-radius: 1px;
            background-color: whitesmoke;
            background-size: cover;
            background-position: center;
            background-image: url(data:img/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAn1BMVEUAAAA8xFE8xFE8xFE/xVMuwEU8xFE8xFE9xlCz6LszwUm26b08xFE8xFE8xVE8xFG56sA5xE6w57iw57g8xE49xE48xVC56sA8xFE9w089w049w048w1A/xVI3w004w04ywUgzwUk8xFG/7Ma56sA8xFEuwEWw57iu5bZEy1e37L646r87xlE6wU23672z5rw8xFGw57i26b256sAzwUlxvNXYAAAAMHRSTlMA8sneDPLa9xoH/Pz88CDn3iZ1a1tJNO2/c2ssExAH/Pzv4tva0tKSfnhhWDs6HhCHXh2DAAAA7klEQVQ4y6WTxxaCMBBFExlJ1ABir9h7I+j/f5shHCUmh9l4N7Pg8mAegShG1GA1b1yIhZ8avIA8D5ZQM4UmkKHs4MJWyi4qtKVUGbigM3BBdlBBZyBbFBxNQQhRCEsgm5Ymy86lMOacX1kuLBJCGgWPeynwb6MiBmKjhEE+vTSH0vqHfSmE+YxSi6AUemoMmC14xhae70+mYdhnVYJCP8ZHhFi/KCJwNIFSOpkBTIMKYQwA/YjSyi10fOCs6TTpCniT3k+TyS0QrLrJaB2dQj5Dm+R/NznHE4pPHjtNmncxxtwDY/z+Qh84k526+AbTPmKwHe0zXgAAAABJRU5ErkJggg==);
        }

        .file-profile {
            margin-left: 10px;
            color: #333333;
        }

        &:hover i {
            opacity: 1;
        }
    }

    .name-tag {
        color: #FD9A17;
    }

    .file-notes {
        position: absolute;
        bottom: 10px; right: 12px;
        line-height: 1;
        font-size: 12px;
        i {
            margin-left: 10px;
            opacity: 0.35;
            transition: opacity .25s;
        }

        span {
            color: #C0C4CC;
        }
    }

    .attachment-list {
        li {
            background-color: unset;
            .name-tag {
                color: inherit;

                padding-left: 20px;
                background: url(data:img/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPCAMAAAA1b9QjAAAAaVBMVEUAAABxcXFmZmZmZmZqampnZ2dnZ2dnZ2dtbW1mZmZmZmZmZmZnZ2dnZ2dnZ2dnZ2doaGiamppnZ2dmZmZoaGhnZ2dnZ2doaGhpaWlqampnZ2dmZmZnZ2dnZ2dnZ2dqampra2tnZ2dmZmaPmcscAAAAI3RSTlMACN3HGdiEVQ696MG4s6ZcJAONdWZNRTUpHtCVfGFAMBOrqlewzgQAAACMSURBVBjTVc5LEoIwEEXRS4IECAQjyE8FdP+LNA1SKe+gB6d68GCoSm0gKSaOBtUnq87AXNJD7II8BGp+VIKQjSQ3vZLbJ7x2ah30KpAWmgP5+gH3nVrotIfkj1yH0E1oCtQwaoSqQCbQOuNLIl2W7E2ugHNhb12KcUSSNjVyUl0NfjOqI9YUpfqM8AVxagau3fH2EgAAAABJRU5ErkJggg==)
                    left center no-repeat;
            }
        }
    }
    
</style>